import React, { useState, useEffect } from 'react';
import { Spin as Hamburger } from 'hamburger-react';
import Logo from '../Images/Logo.svg';
import './Header.css';

const disableScroll = () => {
    document.body.style.overflow = 'hidden';
};

const enableScroll = () => {
    document.body.style.overflow = 'auto';
};

const Header = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);
    const [isOpen, setOpen] = useState(false);
    const [animateList, setAnimateList] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1100);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if (isOpen) {
            disableScroll();
            setTimeout(() => setAnimateList(true), 500);
        } else {
            enableScroll();
            setAnimateList(false);
        }

        return () => enableScroll();
    }, [isOpen]);

    return (
        <header className="header">
            <img className="logo-header" src={Logo} alt="Logo" />
            {isMobile ? (
                <>
                    <div className='absolute top-0 w-full h-20 px-5 text-3xl text-black flex items-center justify-end z-30'>
                        <span>
                            <Hamburger toggled={isOpen} toggle={setOpen} size={30} color='#ffffff'/>
                        </span>
                    </div>
        
                    <div
                        className={`no-select fixed top-0 right-0 h-full bg-custom-dark flex flex-col items-center z-20 transition-transform duration-500 overflow-hidden ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
                        style={{
                            width: '330px',
                        }}
                    >
                        <h3 className="mt-20 text-white text-lg italic h-10 border-b-2 mb-10" style={{ width: '280px', fontFamily: 'Montserrat, sans-serif' }}>Navigation</h3>
                        
                        <ul 
                            className={`text-white transform transition-all duration-500 ${animateList ? 'translate-x-0 opacity-100' : '-translate-x-10 opacity-0'}`}
                            style={{
                                width: '280px',
                            }}
                        >
                            <li className='mb-3 text-xl cursor-pointer hover:translate-x-4 transition duration-300' style={{ fontFamily: 'Inter, sans-serif' }}>Accueil</li>
                            <li className='mb-3 text-xl cursor-pointer hover:translate-x-4 transition duration-300' style={{ fontFamily: 'Inter, sans-serif' }}>Nos prestations</li>
                            <li className='mb-3 text-xl cursor-pointer hover:translate-x-4 transition duration-300' style={{ fontFamily: 'Inter, sans-serif' }}>À propos de nous</li>
                            <li className='mb-3 text-xl cursor-pointer hover:translate-x-4 transition duration-300' style={{ fontFamily: 'Inter, sans-serif' }}>Contactez-nous</li>
                        </ul>
                    </div>
                </>
            ) : (
                <nav>
                    <ul className="menu">
                        <li>Accueil</li>
                        <li>Nos prestations</li>
                        <li>À propos de nous</li>
                        <li>Contactez-nous</li>
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default Header;
