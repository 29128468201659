import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot, faPhone, faEnvelope, faHammer } from '@fortawesome/free-solid-svg-icons'; 
import Logo from '../Images/Logo.svg';
import './Footer.css'; 

function Footer() {
    
    return (
        <footer>
            <div className='container-footer'>

                <div className='footer-top'>
                    <img src={Logo} alt="Logo Beri Montage Sàrl" />
                </div>

                <div className='footer-center'>

                    <div className='link'>
                        <h2>NAVIGATION</h2>
                        <ul>
                            <li>Accueil</li>
                            <li>Nos prestations</li>
                            <li>À propos de nous</li>
                            <li>Contactez-nous</li>
                        </ul>
                    </div>

                    <div className='services'>
                        <h2>NOS PRESTATIONS</h2>
                        <ul>
                            <li>Chauffage</li>
                            <li>Sanitaire</li>
                            <li>Dépannage</li>
                        </ul>
                    </div>

                    <div className='social'>
                        <h2>NOS RÉSEAUX</h2>
                        <ul>
                            <li><span><FontAwesomeIcon icon={faFacebook} /></span>Facebook</li>
                            <li><span><FontAwesomeIcon icon={faInstagram} /></span>Instagram</li>
                        </ul>
                    </div>

                    <div className='coord'>
                        <h2>NOUS CONTACTEZ</h2>
                        <ul>
                            <li><span><FontAwesomeIcon icon={faLocationDot} /></span>Ch. de Liamont 2<br />1059 Peney-le-Jorat, Suisse</li>
                            <li><span><FontAwesomeIcon icon={faPhone} /></span>+41 79 125 46 46</li>
                            <li><span><FontAwesomeIcon icon={faEnvelope} /></span>info@tabrizisarl.ch</li>
                        </ul>
                    </div>
                    
                </div>

                <div className='footer-bottom'>
                    <h3>© 2024 tout droit réservé à AG Sanitaire Sàrl.</h3>
                    <h4><span><FontAwesomeIcon icon={faHammer} /></span>Conçu par LogixMédia</h4>
                </div>
                
            </div>
        </footer>
    );
}

export default Footer;