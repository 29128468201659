import React, { useState } from 'react';
import { Blockquote } from 'flowbite-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Import CSS pour pagination
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import Sanitaire from '../Images/Sanitaire.svg';
import Chauffage from '../Images/Chauffage.svg';
import Dépannage from '../Images/Dépannage.svg';
import Contact from '../Contact/Contact';
import './Info.css';

const Info = () => {
    const [isHoveredSanitaire, setIsHoveredSanitaire] = useState(false);
    const [isHoveredChauffage, setIsHoveredChauffage] = useState(false);
    const [isHoveredDepannage, setIsHoveredDepannage] = useState(false);

    const boxes = [
        {
            title: 'Chauffage',
            color: '#e32021',
            img: Chauffage,
            hoverState: [isHoveredSanitaire, setIsHoveredSanitaire]
        },
        {
            title: 'Sanitaire',
            color: '#0756a4',
            img: Sanitaire,
            hoverState: [isHoveredChauffage, setIsHoveredChauffage]
        },
        {
            title: 'Dépannage',
            color: '#3fa535',
            img: Dépannage,
            hoverState: [isHoveredDepannage, setIsHoveredDepannage]
        }
    ];

    return (
        <>
            <div className="info">
                <div className="container-box">
                    <Swiper
                        spaceBetween={60} 
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        navigation={true}
                        autoplay={{
                            delay: 4000, 
                            disableOnInteraction: false,
                        }}
                        modules={[Pagination, Navigation, Autoplay]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20, 
                            },
                            860: {
                                slidesPerView: 2,
                                spaceBetween: 30, 
                            },
                            1240: {
                                slidesPerView: 3,
                                spaceBetween: 60, 
                            }
                        }}
                    >

                        {boxes.map((box, index) => (
                            <SwiperSlide key={index}>
                                <div className="box" style={{ borderLeft: `${box.color} 5px solid` }}>
                                    <div className="container-img">
                                        <img src={box.img} alt={box.title} />
                                    </div>
                                    <div className="container-title">
                                        <h4>{box.title}</h4>
                                    </div>
                                    <div className="container-button">
                                        <button
                                            style={{
                                                color: box.hoverState[0] ? '#f1f1f1' : box.color,
                                                backgroundColor: box.hoverState[0] ? box.color : 'transparent',
                                                border: `2px solid ${box.color}`,
                                                cursor: 'pointer',
                                                transition: 'background-color 0.3s, color 0.3s',
                                            }}
                                            onMouseEnter={() => box.hoverState[1](true)}
                                            onMouseLeave={() => box.hoverState[1](false)}
                                        >
                                            Découvrir
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </div>

                <div className="container-text">
                    <div className="text">
                        <Blockquote style={{ backgroundColor: '#f9f9f9', borderRadius: '8px', padding: '20px', fontSize: '1.25rem', lineHeight: '1.75rem' }}>
                            <svg
                                className="mb-4 h-12 w-12 text-gray-400 dark:text-gray-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 18 14"
                            >
                                <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                            </svg>
                            <p>
                            "Chez <span style={{fontWeight: '600'}}>AG Sanitaire</span>, nous offrons des <span style={{fontWeight: '600'}}>solutions de qualité</span> pour vos besoins en installations sanitaires, chauffage et dépannage. Notre équipe veille à respecter les normes et à optimiser l'efficacité énergétique. Faites confiance à notre expertise pour un confort <span style={{fontWeight: '600'}}>durable et adapté</span> à votre budget."</p>
                        </Blockquote>
                    </div>
                </div>
            </div>

            <Contact />
        </>
    );
};

export default Info;
