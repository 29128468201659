import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Background from '../Images/Background.webp';
import Background2 from '../Images/Background2.webp';
import Background3 from '../Images/Background3.webp';

const images = [Background, Background2, Background3];

const BackgroundSlider = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [prevImage, setPrevImage] = useState(null);
    const prevImageRef = useRef(null);
    const currentImageRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setPrevImage(currentImage);
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [currentImage]);

    useEffect(() => {
        if (prevImage !== null) {
            gsap.fromTo(prevImageRef.current, { opacity: 1 }, { opacity: 0, duration: 1 });
            gsap.fromTo(currentImageRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
        }
    }, [currentImage, prevImage]);

    return (
        <div className='container-background'>
            {prevImage !== null && (
                <img
                    className='background'
                    src={images[prevImage]}
                    alt="Fond précédent"
                    ref={prevImageRef}
                    style={{ opacity: 1, zIndex: 1 }}
                />
            )}
            <img
                className='background next-image'
                src={images[currentImage]}
                alt="Fond actuel"
                ref={currentImageRef}
                style={{ opacity: prevImage === null ? 1 : 0 }}
            />
        </div>
    );
};

export default BackgroundSlider;
