import React, { useState, useRef, useEffect } from 'react';
import './Home.css';
import Info from '../Info/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import BackgroundSlider from './BackgroundSlider';

const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
    const iconRef = useRef(null);

    useEffect(() => {
        gsap.to(iconRef.current, {
            y: 20,
            repeat: -1,
            yoyo: true,
            ease: "power1.inOut",
            duration: 1.5,
        });

        const handleResize = () => {
            setIsMobile(window.innerWidth < 700);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className='home'>
                <BackgroundSlider />

                <div className="container-text">
                    <h2>CHAUFFAGE FIABLE,<br />CONFORT ASSURÉ</h2>
                    {isMobile ? (
                        <p>
                            AG Sanitaire Chauffage vous accompagne dans tous vos projets, en vous
                            proposant des solutions de chauffage performantes, économiques et
                            parfaitement adaptées à vos besoins spécifiques, pour un confort
                            optimal en toute saison.
                        </p>
                    ) : (
                        <p>
                            AG Sanitaire Chauffage vous accompagne dans tous vos projets,<br /> en vous
                            proposant des solutions de chauffage performantes, <br />économiques et
                            parfaitement adaptées à vos besoins spécifiques,<br /> pour un confort
                            optimal en toute saison.
                        </p>
                    )}
                    <button>À propos de nous</button>
                </div>

                {!isMobile && (
                    <FontAwesomeIcon
                        ref={iconRef}
                        style={{
                            position: 'absolute',
                            bottom: '40px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            color: '#fff',
                            fontSize: '45px'
                        }}
                        icon={faAnglesDown}
                    />
                )}
            </div>

            <Info />
        </>
    );
};

export default Home;
