import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './AG/Header/Header';
import Footer from './AG/Footer/Footer';
import Home from './AG/Home/Home';

const App = () => {
    return (
        <Router>
            <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            <Footer />
        </Router>
    );
};

export default App;
